
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexulhpy5VosHsUMlaCAsx679xWjdo34meZkbBTcBsaOsIMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/index.vue?macro=true";
import { default as login7_T49av_8PuZWEhqUNMMGrHxPDGv266UpNDPc9gXsDEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verifybYh5_3_EHmAqUho3gXhmWksWKlstghSe5aDHTRd2O2EMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemaph5hAGm849jFF5xGsOQ6F2WLTlQfDNTk4Plnz7QXtngUMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usSYF1XDjmjbbbF0QRtzshZjuAo9b0X7iyUFkpDoV7mOQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerNVSMFjELmM1sWPBJ2aG9NKCmOTIDCLUYhC9caHN_s0oMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaints10h5zIiR4hYTmUPOKnfoj7rgBM9avAbH2Eecr87n5TcMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usq3s2awKTvT0qQ16jSNIXCKMn1UBRUYyKlhKMaUh9ykYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as index9OXvnHCxup_kg5e031KIzzoK_sldVxoR4HuYUeKRx88Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexq0RpqRsElQZZbm2iggM2Sa88WGR8Vc06eVf0MmJJBNQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93itE1zc5K4uco_IlzN3a_45UGgRLmxQ5HetAR9kh8ydX1MMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsk8YKDSs4d61IHy_Wk8oG_45xkapWZXnZVuk_45tbJJwc_34Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45useE5imAkf1V0Ti3ynjZTBJJ0Z2b2xD8RjUPlVGF4GfadMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as index8pNmuHemqG2ffJDpwirB2IgsRqO99DlOuGoQd1LlbfgMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyoFrRVAixYXQL0E_45B0KgG9DTfZhSGxwQ_45vKljkMJp_45U0Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordR_6cIevOc84hBmfMmr89BEz68Qb9JH5d8FgdS_45giHykMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexcro0q3URcCXxoUMJpNhqv0yrpPTj988jGdMCgJnfcuMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexi4azuLRHn9q7eh0tKsKf2EjJwM1kbLwrFsihrdp7E4cMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45password7JwZVENpJFCXF26uqhbENOWmTB_3jpaXc6tcDPBPcZYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as indexvEU1LPn3EtfmTif6ajnFb7vU8qk8yrjzCkuBEIzPOzwMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93IVcoRRfhQ_45JUSYbtJZBGfyOee5YyGZC5crts13vl8CsMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesG2k4dJGDIvUNoeXT4AwLgPqDWaMBnflon5hJCsOtTqEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticecEnqoknTH352xToHX_gDJzUMPLxX4qGfhFLWJG2Q_45XwMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexUF3QNliZCguiOBboZ3VEwpSHyR698iZrG1_YYIdzyeUMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordevWyWECUyRUTCBBSVv0T6XoAf_45ZRZQOlUjGAEmiuC98Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backgStyP0D1W6_XKktkysIlYPcNn2zlxWRNUFUGtoPEJE0Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as merge_vzRW4lV_45TzmA9KXCBGWOH_450pSmgld8Iy2jNhRFoyMsMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexWMBySZi96nUmpPfKNQ26j3xHLmiYtzBp2ZRl7w_45FLEcMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_938aMu8G2Dc3BUw7CNMJwai2u0gn_45KPluYXLvqtp_ek0wMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youmpNKo8Z8QNXbfJFvDMAp1yUJjW_MdPd1DpmWcTpt_45RMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as indexHhRlRun1safWj3tQWEaI51096GdZDkbDSQB8RyoDh3kMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indextWlYBoHPfGNu8cBMZjBLdvgUrYO_ueMAoVcZ_45u2r4EAMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93w2oToJsMy4GzDz2inBaMVxplNCGj09Pmi_45Ves2J02ucMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexxkICgozFHwkcvZ6_yVDNDehVf4zg6BIiM9MiAFMx9csMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as index2GBsfOuqP5nEy3YzFiBo0O1HCtSOn_LFQlMYQZvZiKMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93r1HfRjPP0ZypB7VhmYPbJfhrBNxeJzBuCo9EwB6UZo4Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackyN8dgUOKFhQFk8Va53hYq8ZzkOLu1cy_45MTjya1IuDsQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claim48MrtTQ4MJwok05fqOfEKE3KXAalbosxN7oMgXKOxVwMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as index_Gq0l7CZGJ_45FanrNzKBMn1NVKBA1xn0W0lNd6Q_45Bd4sMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youJtso6cabpv6_TntxwteiEh5NfIzBMjlhydkR3kN80soMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorYztM0PfScH_45DYp2Z5ZbU73Z7ws4fLUn9khvIdcyOrF0Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexPXnFl_zsAr71O1H0cddXpFJpMz2A4JPfE_45AXfJR5w2YMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexVCl_45cRqUFA6n3_I8dofB_U0muOyV9xqvbGDPEgg3GS4Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as error86bkf2FPXPQnoDNmWPGFYz0yaC89IlI9nZMAhyGtffQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93hy0UYTH37CMrCWKCWXQh0Hfou8oePP9ainYY3OjBc_sMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as details3SdzGrSzcRtx9ITkil58bFPNdKbP6a4amUkbA_45UZeEMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesWiftvR8NzBFYUVOhwK7PwgsszZgz3bp4STJpepXGyuAMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93Jkp6Jtnsn1avDy_45V5kTxq04TeSWcnPSHE61RMli4uqYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexiX6pv7PuofWKYOGmSaT1krHl88YSPqpmNbFJyWNlEGQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsDO3JgDAj4pmtBBZrK3Il2vpmTveUMnJpFr1DWxC1WGQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexuQSp6MgsEnHneXLt1sHCcmpIXW1MFcW3ZJSmQ6p7Y2YMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chat6jbxhNtHktT6nRcdaIVOVpJ_45IvFtGg1Dvwqukz7_PG8Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45salePe7T_457OuOVbKIHevf7LAJHRC_6lv8nERfuEk2fAz7_450Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45you8axS__6tVT2l5ClBrYYXk0vG_h0HRxA5wBzVAljPuNEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensiveeAqy9ooh83Stjc6UHki5L_4iMLhnDGutMegfa5iP_45noMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_9329rlhuciAAkBV2KyW1cOfWcR1ebOZn2UNEiZB1ZtRYEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as add_4563RiUk8RzyJLnAm7snxA48QSELyMy2vacVIOXDJK2EMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexKDbcgErUIa_s3Ri0uJlIHS4nmpmK1L4R_4532lso0gvJMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_9318eHoZ1bFwJ5l9BcWoMVbX_45YXHwipH_45dxCKmNv7pQHQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackr_457Nsl_JAiAegYLajh5EVH8ayrKNv4wAbpVQHWI6bhEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93qW_Re0o6Jh6tyV6lqmxyQETU8qJVfE1tKzUuENyjVoYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45you3WSsrSfn6yfmXRg_s9e2J9WV_UqNlvPM1_VY_45Zn1WGIMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bXPHy479FZGw1c8gpUuvDkKNnUzLINx4nPGh0ZzLgxj0Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexKcRyXS0gnvKeaaWw3FAEXBn5aBfcG9GNAfmqNn4xYTEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93n5d4sWo4KeC0J3JCMo4vqjtJsXwftzGOaY2CoPK3VoYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93U59_45q5H9UrDrEIJRs5qBe0YsNf0SstIqtlnmD9uPPi0Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as index3bIoLH0syIcrOSwh02sSZcn_f_45oNxiJpr9M0FkPOxCcMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addAHaUJVvRyGw0Dpz2oD7xgN2gdbiWNTeFy32UDoLE3AsMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93GKf3OoKGyA35H1B4pjZkEdy8EylQ5eN58ZizzAzZn5wMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsX9BOncvlUwfvyfGUqY89zbABqxDkHaC8qHJvxih55u4Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as digital_45eicbKZPGFnsBkVo8G5swVKsQEGXPQnVJL2mWjf9PJnF_2AMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youIrSg5KZxL07RsjSFtmMYDyXvuZZJ7vE2Y_0XDbynq2kMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_933GHCogoMjgDaQMnwTv8gdbJrHQWZJaS6IAfsfeYQrvcMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93VQgdSsf5UGsNDu_45_h1sHWvOF9CA5DS6NHwU8NffPjXoMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as step03zJifmYM2O8FheJ3h4JbtEARVzeK_GBjgwDkqWTBanYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as detailsGwAY1148JTcX2A5xV_vcR09JQ0jSF_Oyfg4dnpxLNlMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsTRuuwaHMApx1zWcsY6VBVtO2Gw7uA6HuibZ_DV1cr0gMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexjUhQHbL7WUQLTHEzP8Ph4kUH5spgwZyLEG6OMWMezsoMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupMlqLO1H9uLQhR1jfgnT30bwf6mfS4vMd3bSkbtOW3CMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45you4cr8yns4YMvCWY8AaTI25nEq8VMN0OcNTHZrkiFo2jMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93Go_nOY988EptajcEp8x4jhnMsFE2H2DAD7en2VFTNOkMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as _91step_934sXJd4EMoYBSf5svTA0DLhjj8_45aY4W7VY8XtO6hvowgMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93vy9A_tEWK7F9DrTZSDmZBNzh_NaFt_Af_45kLgW7P7SQ8Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0AkeQ3zacbHLI86_455ZQNm6_45FYCJ1Je3E8vbnAYb5YNMsMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersKqhWcUU9LTn0_0nUzqEcparI6SajXfVUqTOo4rKr1lQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youWpIfH32_45yJiJg_45OA4UKAHTRLtrUklYZzEa_wmYRmZasMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailspNChCvwoRYNzXNcvrTnQDx_sVlnV95B7zDPAo9j_yYIMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsPrB2Y1Qyn00QYYJiAzAPqx8_nsYjd51u_45oA7Ew_45cTuUMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93B4PfVSqeCSoGClUcSVbz5CpC8g186JzFbkKNJWSFrysMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebate37mTYde7CI2_45ZhJksQ0yV_45rXnm_45llaTl_9AeFKJtNKEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93KtQOqJzdJAcj3wxqL3ofcmlS3IFxOTXZaFEwMJMUNr4Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsy3bppuwLDobZy1tWO32f33ioIsgfeWMWWldbaK6C89YMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0QP2qPaQhjge_LVexlU9UyTdLT2iidoGmjsoE6D6_X5MMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexQ9jSMLW2wOYQ3ld5qzz0PMf_1nbTLQBrKCQV4WEKbBAMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as index1k_45IHiDw2hgF5sTFHO3wFuCT9_45pdgUC2QZD3_5ZRdLoMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsqmhLXpfhE_456LBjKDTWErNB8_o2TsODFs1wmjIz0kgYAMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsaXLHzMdMFTd3VFUXpbpfAXGnJkSfHAcPHYkNba_mtX4Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as indexJABxr0Nw7LA9wDBnVBWc0pitYwsbcoWs6onKPOhMzucMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailskn5_45HLIw6f_45oNO13iLCec01coKLQneyw2ojJ2RcHu80Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexYEgxoZkJ_45wxLDZoOPTXbIIBBrsC79hjFHmNyAO1ipboMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successGZdwSEIx5XSjzizgCGgr_45mn3x4196tDMbBqrLENR8ZkMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerf_45jva2_zXjtnxfKcKz1UtENhrZUiWHt708lRQ4IK750Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsj1MNgELJtA5SxbSTUeAsF2kWNzrT8EYWkrERZpuPpUAMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbour3NhhgsxmeVXNLwAEXCqK_458caOjpXLLBzj5AEX1pv71cMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93KifsOj6TcCj5cHwfYWp57GIAI4jyFFZhj33tMJgTslYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as index217cKj17HCeSt8hlAzlhnJ8bJHnRrcDw3FFcS01hmMYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93cDnydcSzamgcNDxYUD7_45GqzmV_2gBlRp6_iHwC2VTYcMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexZ8tH_45jnNBnBj_45J8DrbnSWSef_iZanqNKvQIPBXznoBsMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93nIhbErGMiSHVwfwG9_YrerAquJbwvMgG_x5rzW59zbwMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as indexXdYrAo4fz1uyMcWoOlyY0FPj1nA3ebtMHJlLHZNqfvEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexi0mQdlIEN5P3qbaCDVikKLCQAN3VPrf_45gGahe_45Vnj7wMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsRU9QLoWxd7Wx2NdI4y4Jr2YgRxUcDKx_H2GgTUWmrNgMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expired3xD7gTqj_UZXNrH3mQLW5JlOv_2IXn691xNTV_45eIkNsMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexwYToKGW1muJfmhVA8lG9gxDOt8fuyRIq70tsUc1a2bMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youkAfTWaDWCT18N58b2Q7FOug43V83g0K_45xhGWQXgWw1IMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsjHKV69CSocSz4RCFfHRydZwFDd2Y2VOEXhQPSZIv208Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as confirmationrnjgnZvMjPM5LxmLtNQ53q9ltDdsf0CFhHBrbDE_456v0Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionxsbWu7tTLDKltD1ofadnvz5w9gdm8gNrAShfMzcVto4Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsQ18_45zMFD_455jXF2Oc8uEruelKbG6NAr_h4FxjGJzxsMUMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationMbU0GDOVAEzuxnThyYWm1UQHD_45Xcw_45wbJOMFGft1leEMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93VQVOCvuByYslgS4x4aAi3BjIb55iQxZbm9892GCdNEgMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93JHvGfcd4PaxOSIJ9c321to0ylwwuKr6vYKXKSoYcdOYMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93AEEuirWKKTMUGjTjCivIQtwzagMV7U6h7dvB_SQzXpQMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93HQhQ3SDNjeM0a_45Nh4eEe920lKq0MmUcyrHKq9LaM95oMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexBf7vzDWtdJfygFXz41gua5D5a0gAGbq0kjTfnFSH3ykMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailsGEVwI3RaqMwxH0bDGTBuZFBvHgD0Ngfi1R8ExXL14MMMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93Mp1ohkqbRS4icqqTAhN_Jw8XHU2_7y6BreEvxbJy1S8Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93lsyFgrrDDGKVYfNvIGGcHTjikicj02weOovY6PhlulcMeta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexsmsFZePSANCWOk91fH6y_45_fiUaMVQnWL4JikIeguhB4Meta } from "/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login7_T49av_8PuZWEhqUNMMGrHxPDGv266UpNDPc9gXsDEMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerNVSMFjELmM1sWPBJ2aG9NKCmOTIDCLUYhC9caHN_s0oMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordR_6cIevOc84hBmfMmr89BEz68Qb9JH5d8FgdS_45giHykMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexi4azuLRHn9q7eh0tKsKf2EjJwM1kbLwrFsihrdp7E4cMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password7JwZVENpJFCXF26uqhbENOWmTB_3jpaXc6tcDPBPcZYMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordevWyWECUyRUTCBBSVv0T6XoAf_45ZRZQOlUjGAEmiuC98Meta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_938aMu8G2Dc3BUw7CNMJwai2u0gn_45KPluYXLvqtp_ek0wMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexxkICgozFHwkcvZ6_yVDNDehVf4zg6BIiM9MiAFMx9csMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index_Gq0l7CZGJ_45FanrNzKBMn1NVKBA1xn0W0lNd6Q_45Bd4sMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexVCl_45cRqUFA6n3_I8dofB_U0muOyV9xqvbGDPEgg3GS4Meta?.props ?? {"verificationNeeded":true},
    meta: indexVCl_45cRqUFA6n3_I8dofB_U0muOyV9xqvbGDPEgg3GS4Meta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsDO3JgDAj4pmtBBZrK3Il2vpmTveUMnJpFr1DWxC1WGQMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexuQSp6MgsEnHneXLt1sHCcmpIXW1MFcW3ZJSmQ6p7Y2YMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackr_457Nsl_JAiAegYLajh5EVH8ayrKNv4wAbpVQHWI6bhEMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebate37mTYde7CI2_45ZhJksQ0yV_45rXnm_45llaTl_9AeFKJtNKEMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsqmhLXpfhE_456LBjKDTWErNB8_o2TsODFs1wmjIz0kgYAMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerf_45jva2_zXjtnxfKcKz1UtENhrZUiWHt708lRQ4IK750Meta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsj1MNgELJtA5SxbSTUeAsF2kWNzrT8EYWkrERZpuPpUAMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93KifsOj6TcCj5cHwfYWp57GIAI4jyFFZhj33tMJgTslYMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionxsbWu7tTLDKltD1ofadnvz5w9gdm8gNrAShfMzcVto4Meta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93VQVOCvuByYslgS4x4aAi3BjIb55iQxZbm9892GCdNEgMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93JHvGfcd4PaxOSIJ9c321to0ylwwuKr6vYKXKSoYcdOYMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93HQhQ3SDNjeM0a_45Nh4eEe920lKq0MmUcyrHKq9LaM95oMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailsGEVwI3RaqMwxH0bDGTBuZFBvHgD0Ngfi1R8ExXL14MMMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93Mp1ohkqbRS4icqqTAhN_Jw8XHU2_7y6BreEvxbJy1S8Meta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93lsyFgrrDDGKVYfNvIGGcHTjikicj02weOovY6PhlulcMeta || {},
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src1289974734/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]